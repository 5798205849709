import React, { useState, useCallback, Fragment } from 'react'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { graphql } from 'gatsby'
import { Layout, Hero, Panel } from '../components'
import { ButtonGroup } from '../components/ButtonGroup'
import tooth from '../../static/core/tooth-brown.svg'
import { sanitizeFormValue, removeTrailingSlash } from '../utils'

const FileDrop = loadable(() => import('../components/FileDrop'), {
  fallback: (
    <section
      className="btn is-loading"
      style={{
        height: '12rem',
        width: '100%',
        background: '#f1eeea',
      }}
    />
  ),
})

const botfield = uuidv4()
const defaultFormState = {
  [botfield]: '',
  formName: 'appointment',
  name: '',
  email: '',
  phone: '',
  zip: '',
  dob: '',
  insurance: '',
  insuranceName: '',
  insuranceGroup: '',
  insuranceId: '',
  insuranceImage: '',
  existing: '',
  preferredTime: '',
  message: '',
}

const BookPageTemplate = ({ hero: { header }, useBgPattern, slug }) => {
  const [values, setValues] = useState(defaultFormState)
  const [msg, setMsg] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const btnClasses = [
    'submit',
    'btn',
    isWorking ? 'is-loading' : '',
    isSuccess ? 'is-success' : '',
  ]
    .filter((item) => item)
    .join(' ')
  const btnText = isSuccess ? 'Success!' : 'Submit Appointment Request'

  function handleChange(e) {
    const { name, value } = e.target
    const newState = _.cloneDeep(values)
    newState[name] = sanitizeFormValue(value, name)
    console.log(newState[name])
    setValues(newState)
  }

  function handleInsuranceToggle(val) {
    const newState = _.cloneDeep(values)
    newState.insurance = sanitizeFormValue(val, 'insurance')
    setValues(newState)
  }

  const handlePhotoDrop = useCallback(
    (file) => {
      const newState = _.cloneDeep(values)
      newState.insuranceImage = file
      setValues(newState)
    },
    [values],
  )

  async function handleSubmitAsync() {
    try {
      const body = _.cloneDeep(values)
      if (!!body.insuranceImage) {
        body.insuranceImage = btoa(body.insuranceImage)
      }
      const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      }
      const response = await fetch('/.netlify/functions/form', request)
      const data = await response.json()
      setIsWorking(false)
      setIsSuccess(response.ok)
      setMsg(
        response.ok
          ? 'Thank you! Your request has been received and we will be in touch shortly.'
          : `Error: ${data.error}`,
      )
    } catch (e) {
      console.error(e)
      setIsWorking(false)
      setIsSuccess(false)
      setMsg(`Error: ${e.message}`)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    setMsg('')
    if (values[botfield]) {
      return null
    }
    if (values.insurance === 'photo' && !values.insuranceImage) {
      setMsg('Please upload a photo or enter your insurance details manually.')
      return null
    }
    setIsWorking(true)
    handleSubmitAsync()
  }

  const pageClasses = [
    'content-page',
    `page-${
      slug === '/'
        ? 'home'
        : slug
            .split('/')
            .filter((i) => i)
            .join('-')
    }`,
    useBgPattern ? 'tooth-bg' : '',
  ]
    .filter((i) => i)
    .join(' ')

  return (
    <div id="content" className={pageClasses}>
      <Hero header={header} />
      <section id="inner-content" className="cf">
        <Panel panelType="contact" align="center">
          <img src={tooth} style={{ width: '50px' }} alt="happy tooth" />
          <p>Fill out the form below to book an appointment.</p>
          <form id="book-form" onSubmit={handleSubmit} method="post">
            <div hidden>
              <input
                name={botfield}
                type={'text'}
                aria-label={botfield}
                onChange={handleChange}
                tabIndex="-1"
              />
            </div>
            <input
              type="text"
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Name"
              aria-label="Name"
              required={true}
            />
            <input
              type="email"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Email"
              aria-label="Email"
              required={true}
            />
            <input
              type="phone"
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              placeholder="Phone"
              aria-label="Phone"
              required={true}
            />
            <div className="field">
              Are you an existing patient?
              <input
                type="radio"
                id="existing[yes]"
                name="existing"
                value="yes"
                checked={values.existing === 'yes'}
                onChange={handleChange}
                required={true}
              />
              <label htmlFor="existing[yes]"> Yes</label>
              <input
                type="radio"
                id="existing[no]"
                name="existing"
                value="no"
                checked={values.existing === 'no'}
                onChange={handleChange}
                required={true}
              />
              <label htmlFor="existing[no]"> No</label>
            </div>
            {values.existing === 'no' && (
              <Fragment>
                <fieldset
                  className="field"
                  style={{
                    border: '1px dashed #e2dad2',
                    height: 'auto',
                    padding: '0.5rem',
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      padding: '0.5rem 0 0.75rem 0',
                      lineHeight: 'normal',
                      fontWeight: 'bold',
                    }}
                  >
                    Dental insurance information
                  </p>
                  <ButtonGroup>
                    <button
                      className={`btn ${
                        !values.insurance ? 'is-selected' : ''
                      }`}
                      style={{ width: '33.333%' }}
                      onClick={(e) => {
                        e.preventDefault()
                        handleInsuranceToggle('')
                      }}
                    >
                      No insurance
                    </button>
                    <button
                      className={`btn ${
                        values.insurance === 'photo' ? 'is-selected' : ''
                      }`}
                      style={{ width: '33.333%' }}
                      onClick={(e) => {
                        e.preventDefault()
                        handleInsuranceToggle('photo')
                      }}
                    >
                      Upload photo
                    </button>
                    <button
                      className={`btn ${
                        values.insurance === 'manual' ? 'is-selected' : ''
                      }`}
                      style={{ width: '33.333%' }}
                      onClick={(e) => {
                        e.preventDefault()
                        handleInsuranceToggle('manual')
                      }}
                    >
                      Fill out manually
                    </button>
                  </ButtonGroup>
                  {values.insurance === 'photo' && (
                    <div
                      className="field"
                      style={{ padding: 0, height: 'auto', marginBottom: 0 }}
                    >
                      <label
                        htmlFor="insuranceImage"
                        style={{
                          padding: '0 0.5rem 1rem 0.5rem',
                          display: 'block',
                          lineHeight: 'normal',
                        }}
                      >
                        Upload a photo of your dental insurance card, showing
                        name, group, and ID number
                      </label>
                      <FileDrop
                        callback={handlePhotoDrop}
                        inputId="insuranceImage"
                      />
                    </div>
                  )}
                  {values.insurance === 'manual' && (
                    <Fragment>
                      <input
                        type="text"
                        id="insuranceName"
                        name="insuranceName"
                        value={values.insuranceName}
                        onChange={handleChange}
                        placeholder="Insurance name"
                        aria-label="Insurance name"
                        required={values.insurance === 'manual'}
                      />
                      <input
                        type="text"
                        id="insuranceGroup"
                        name="insuranceGroup"
                        value={values.insuranceGroup}
                        onChange={handleChange}
                        placeholder="Group name/number"
                        aria-label="Group name/number"
                        required={values.insurance === 'manual'}
                      />
                      <input
                        type="text"
                        style={{ marginBottom: 0 }}
                        id="insuranceId"
                        name="insuranceId"
                        value={values.insuranceId}
                        onChange={handleChange}
                        placeholder="Enrollee ID number"
                        aria-label="Enrollee ID number"
                        required={values.insurance === 'manual'}
                      />
                    </Fragment>
                  )}
                  {!!values.insurance && (
                    <div className="fieldgroup">
                      <input
                        type="text"
                        id="dob"
                        name="dob"
                        value={values.dob}
                        onChange={handleChange}
                        placeholder="Date of Birth (MM / DD / YYYY)"
                        aria-label="Date of Birth (MM / DD / YYYY)"
                        required={!!values.insurance}
                      />
                      <input
                        type="text"
                        id="zip"
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                        placeholder="Zip code"
                        aria-label="Zip code"
                        required={!!values.insurance}
                      />
                    </div>
                  )}
                </fieldset>
              </Fragment>
            )}
            <input
              type="text"
              id="preferredTime"
              name="preferredTime"
              value={values.preferredTime}
              onChange={handleChange}
              placeholder="Preferred date/time (see Office Hours below)"
              aria-label="Preferred date/time (see Office Hours below)"
            />
            <textarea
              id="message"
              name="message"
              rows={10}
              onChange={handleChange}
              placeholder="Message (optional)"
              aria-label="Message (optional)"
            />
            <button
              className={btnClasses}
              type="submit"
              form="book-form"
              disabled={!!isSuccess}
            >
              {btnText}
            </button>
          </form>
          {!!msg && (
            <div className={`notification ${isSuccess ? 'success' : 'error'}`}>
              {msg}
            </div>
          )}
        </Panel>
      </section>
    </div>
  )
}

BookPageTemplate.propTypes = {
  hero: PropTypes.shape({
    header: PropTypes.string,
  }),
  slug: PropTypes.string.isRequired,
  useBgPattern: PropTypes.bool,
}

const BookPage = ({ data }) => {
  const {
    frontmatter: { hero, useBgPattern },
    fields: { slug },
  } = data.markdownRemark
  const pageProps = {
    hero,
    slug,
    useBgPattern,
  }
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  return (
    <Layout location={location} data={data}>
      <BookPageTemplate {...pageProps} />
    </Layout>
  )
}

BookPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BookPage

export const pageQuery = graphql`
  query BookPageTemplate($id: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
      }
      frontmatter {
        ...seoFields
        ...heroFields
        templateKey
        useBgPattern
      }
    }
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import './ButtonGroup.scss'

export const ButtonGroup = ({ children, className }) => (
  <div className={className ? `${className} buttongroup` : 'buttongroup'}>
    {children}
  </div>
)

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
